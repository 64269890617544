<!-- <div *ngIf="status" class="row mx-0">
  <div class="col-12 px-0">
    <router-outlet></router-outlet>
  </div>
</div> -->

<div style="height:100vh;width:100vw;background-color:#fff" *ngIf="showSpinner">
    <div class="loader" style="margin: auto;margin-top: 35vh;"></div>
    <img src="/assets/images/meta/jodi365-logo.png" style="max-height: 50px;margin: auto;display: block;margin-top: 40px;" alt="">
</div>

<router-outlet></router-outlet>