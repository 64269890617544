import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() { }

  // setting to local (GLOBAL FUNCTION)
  setToLocal(key: string, value: any): any {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      // console.log('error is setting in local', err);
    }
  }

  // getting from local (GLOBAL FUNCTION)

  getFromLocal(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      // console.log('error is gettin from local', err);
    }
  }

  // removing token
  removeToken(key: string): any {
    try {
      if (key === 'user') {
        // const storageLength:number = localStorage.length;
        // console.log(key);
        // console.log(storageLength);
        // for(var i =0; i < storageLength; i++){
        //   if(localStorage.key(i) != 'NotShowAgain'){
        //     //console.log(localStorage.key(i));
        //     localStorage.removeItem(localStorage.key(i));
        //   }else{
            
        //   }
        //   console.log(localStorage.key(i));
        // }
        localStorage.clear();
        
       // window.location.reload();
       
      } else {
      localStorage.removeItem(key);
      }
     
     
      
    } catch (err) {
      // console.log('error is removing from local', err);
    }
  }
}
