<!-- <div class="row mx-0">
  <div class="col-12 px-0"> -->

<div class="row mx-0 p-2">
  <div class="col-12 px-0 m-md-auto">
    <div class="row mx-0">
      <div class="col-9 px-0 text-right">
        <div class=" oswald j-h2">How can we help you?</div>
        <div class="sub-heading mb-2 mr-3">
          Got any concerns or feedback?
        </div>
      </div>
      <!-- <div class="col-3 px-0 text-right">
        <button mat-close mat-icon-button (click)="goBack()">
          <span class="fa fa-times fa-lg"></span>
        </button>
      </div> -->
    </div>
    <div class=" col-12 px-0 text-center sub-heading my-1 mb-2">
      For quicker answers, <a class="custom-anchor" href="https://faqs.jodi365.com/" target="_blank">BROWSE THE HELP CENTER</a>
  </div>
    <form [formGroup]="ContactusForm" (ngSubmit)="onSubmitContactUs()" class="row mx-0">
      <div class="bg-white rounded col-12 justify-content-center">

        <!-- First name block -->
        <div class="row mx-0 mt-1 ">
          <div class="col-6 px-0">

            <mat-form-field appearance="outline" class="w-100">
              <mat-label class="c-gray body-text">First Name</mat-label>
              <input matInput placeholder="" formControlName="firstName" type="text" required autocomplete="off" maxlength="40">
            </mat-form-field>

            <!-- error for name -->
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('firstName').hasError('required') && isSubmitted">
              <span class="pr-1"><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span>First Name is required </span>
            </mat-error>

          </div>

          <!-- Last name block -->
          <div class="col-6 pr-0">

            <mat-form-field appearance="outline" class="w-100">
              <mat-label class="c-gray body-text">Last Name</mat-label>
              <input matInput placeholder="" formControlName="lastName" type="text" required autocomplete="off" maxlength="40">
            </mat-form-field>

            <!-- error for name -->
            <!-- <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('lastName').hasError('required') && isSubmitted">
              <span class="fa fa-exclamation-triangle pr-1"></span>
              <span>Last Name is required </span>
            </mat-error> -->

          </div>
          <!-- <div class="col-6 pr-0">
  
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Last Name" formControlName="lastName" type="text" autocomplete="off">
                  </mat-form-field>
  
                </div> -->
        </div>


        <!--------Email------->
        <div class="row mx-0  justify-content-center">
          <div class="col-12 px-0">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label class="c-gray body-text">Email Address</mat-label>
              <input matInput placeholder="Email Address" formControlName="email" type="email" required
                autocomplete="off">
            </mat-form-field>
            <!-- error for mail -->
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('email').hasError('required') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Email is required </span>
            </mat-error>
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('email').hasError('email') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Email address is invalid </span>
            </mat-error>
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('email').hasError('maxlength') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Please stay within the characters limit. </span>
            </mat-error>
          </div>
          <!-- <div class="col-6 pr-0">
  
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Last Name" formControlName="lastName" type="text" autocomplete="off">
                  </mat-form-field>
  
                </div> -->
        </div>

        <!-- phone -->

        <div class="row mx-0 ">
          <div class="col-6 px-0">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Country code</mat-label>
              <mat-select formControlName="countryCode">
                <mat-optgroup *ngFor="let Ccode of countryCodelist" [label]="Ccode.label">
                  <mat-option *ngFor="let data of Ccode.dataArray" [value]="data.value">{{data.name}}</mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('countryCode').hasError('required') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Country code is required </span>
            </mat-error>
          </div>

          <div class="col-6 pr-0">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label class="c-gray body-text">Mobile Number</mat-label>
              <input matInput formControlName="phoneNumber" type="number" required autocomplete="off">
            </mat-form-field>
            <!-- error for Phone -->
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('phoneNumber').hasError('required') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Phone Number is required </span>
            </mat-error>
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('phoneNumber').hasError('min') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Should be greater than 6 digit </span>
            </mat-error>
            <mat-error class="text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('phoneNumber').hasError('max') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Should not exceed 10 digit </span>
            </mat-error>
          </div>

        </div>

        <!-- category -->

        <div class="row mx-0  justify-content-center">
          <mat-form-field appearance="outline" class="col-12 px-0">
            <mat-label>Select category</mat-label>
            <mat-select *ngIf = "!hideCheck && !deleteCheck && !needHelp && !handpickedMatchesCheck && !connectWithMatchCheck && !isLiveEvents && !isUnivercityData" formControlName="subject" required disableRipple>
              <mat-optgroup *ngFor="let group of categoryGroups" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let subCategory of group.category" [value]="subCategory.viewValue">
                  {{subCategory.viewValue}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-select *ngIf = "hideCheck" formControlName="subject" required disableRipple [(ngModel)]="valueForHideProfile">
              <mat-optgroup *ngFor="let group of categoryGroups" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let subCategory of group.category" [value]="subCategory.viewValue">
                  {{subCategory.viewValue}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-select *ngIf = "deleteCheck" formControlName="subject" required disableRipple [(ngModel)]="valueForDeleteProfile">
              <mat-optgroup *ngFor="let group of categoryGroups" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let subCategory of group.category" [value]="subCategory.viewValue">
                  {{subCategory.viewValue}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-select *ngIf = "needHelp" formControlName="subject" required disableRipple [(ngModel)]="valueForNeedHelp">
              <mat-optgroup *ngFor="let group of categoryGroups" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let subCategory of group.category" [value]="subCategory.viewValue">
                  {{subCategory.viewValue}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-select *ngIf = "handpickedMatchesCheck || isLiveEvents || isUnivercityData" formControlName="subject" required disableRipple [(ngModel)]="valueForHandpickedMatch">
              <mat-optgroup *ngFor="let group of categoryGroups" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let subCategory of group.category" [value]="subCategory.viewValue">
                  {{subCategory.viewValue}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-select *ngIf = "connectWithMatchCheck" formControlName="subject" required disableRipple [(ngModel)]="valueForCompleteProfileViewPhone">
              <mat-optgroup *ngFor="let group of categoryGroups" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let subCategory of group.category" [value]="subCategory.viewValue">
                  {{subCategory.viewValue}}
                </mat-option>
              </mat-optgroup>
            </mat-select>

          </mat-form-field>
          
          <!-- error for subject  -->
          <mat-error class="col-12 px-0 text-left body-subtext c-alert-danger"
            *ngIf="ContactusForm.get('subject').hasError('required') && isSubmitted">
            <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
            <span> Category is required </span>
          </mat-error>
        </div>

        <!-- comment -->

        <div class="row mx-0 ">
          <div class="form-group col-12 px-0">
            <label class="c-gray small-text" for="comment">Comments*</label>
            <textarea placeholder="Enter your comments" formControlName="textArea" class="form-control" [ngClass]="alertRemiderBoxOne ? 'alertRemiderClass' : (dangerReminderBoxOne ? 'dangerReminderClass' : '')" rows="3"
              id="comment" (blur)="onBlurCommentMethod()" required maxlength="201" style="resize:none;" > </textarea>

              <!-------Error Colour show --------->
              <div class="col-12 px-0 pt-1 opensans-regular body-subtext maxCharClass smaller-text">
                <i>
                  <span *ngIf="alertRemiderBoxOne" class="warning-alert"><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
                  <span *ngIf="!alertRemiderBoxOne && dangerReminderBoxOne" class="danger-alert"><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
                  <span style="padding-left: 10px;"
                    [ngClass]="alertRemiderBoxOne ? 'alertRemiderClassForLimit' : (dangerReminderBoxOne ? 'dangerReminderClassForLimit' : '')">{{numberOfCharacters}}/{{maxNumberOfCharacters}} characters max</span>
                  </i>
              </div>
              <!----------------------------------->

            <!-- <mat-error *ngIf="checkLengthFeedback" class="mt-1 c-alert-danger mat-error">
                <span class="fa fa-exclamation-triangle"> </span> Please stay within the characters limit.
            </mat-error> -->
            <mat-error class="pt-3 text-left body-subtext c-alert-danger"
              *ngIf="ContactusForm.get('textArea').hasError('required') && isSubmitted">
              <span class=""><fa-icon [icon]="faExclamationTriangle"></fa-icon></span>
              <span> Comment is required </span>
            </mat-error>
          </div>
        </div>

        <!-- <div class="mt-4">
                <button mat-raised-button (click)="onSubmitContactUsForm()" color="primary"
                  class="md-btn w-100">SUBMIT</button>
              </div> -->
      </div>
      <div class="col-12 px-0 w-100 h-25 text-center ">
        <button [disabled]="holdSubmitButton" mat-raised-button color="primary" type="submit">SUBMIT</button>
      </div>

    </form>
  </div>
  <!-- <div class="col-md-6 col-lg-7 px-0 text-center d-none d-md-block" style="background-color: #f8f7fd;min-height: 100vh">
                <div class="row mx-0">
                    <div class="col-12 px-0 bg-image">
                        <div class="position-absolute blue-skin w-100" style="min-height: 100vh;top:0px"></div>
                        <div class="row mx-0 mt-5">
              <div class="col-6">
                  <div class="d-inline-flex">
                      <img src="./../../../../assets/images/meta/placeholder.svg" class="rounded" alt="addresss"
                        style="height:20px;">
                        <div class="pl-1"  style="font-size:16px ;color:#222222;"><b>Address</b></div>
                    </div>
                <p style="color:white; font-size:14px;">2, Thomas Nagar, 2nd Floor, Little Mount,<br> Saidapet, Chennai, Tamil Nadu 600015</p>
              </div>
              <div class="col-6">
                  <div class="d-inline-flex">
                      <img src="./../../../../assets/images/meta/call.png" class="rounded" alt="call" width="20" height="20">
                      <div class="pl-1" style="font-size:16px ;color:#222222;"><b> Lets Talk</b></div>
                    </div>
                <p style="color:white;font-size:14px;">+093456 34365<br>
                  contact@gmail.com</p>
              </div>
              </div>
                    </div>
                </div>
            </div> -->


</div>
<!-- <div class="text-center">
  <span>Email: support@jodi365.com</span><br>
  <span>Call: +91 934 5634 365 or +1 (305) JODI365</span><br>
  <span>Hours of operation: 10am–7pm IST, Mon–Fri; 10am–5pm IST, Sat–Sun</span>
</div> -->
