import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init, setSessionId } from '@amplitude/analytics-browser';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function() {};
  }
}
//if ((window.location.href.indexOf("testing.jodi365") > 0) || (window.location.href.indexOf("jodi365") > 0)){ 
  if((environment.production)){
    init(environment.amplitudeKey,'',{
      attribution: {
      // initialEmptyValue: "none",
      disabled: true,
      }
      });
    setSessionId(Date.now());
    }
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
