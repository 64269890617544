import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface category {
  // value: string;
  viewValue: string;
}

interface categoryGroup {
  disabled?: boolean;
  name: string;
  category: category[];
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit {

  ContactusForm: UntypedFormGroup;
  isError = false;
  isSubmitted = false;
  apiStatus = false;
  localdata;
  countryCodelist;
  pageTitle;
  categoryGroups: categoryGroup[] = [
    {
      name: 'RELATED TO MATCHES',
      category: [
        { viewValue: 'Connect with a match' },
        { viewValue: 'Get relevant matches' },
        { viewValue: 'Show more matches' },
        { viewValue: 'Block a user' },
        { viewValue: 'Report suspicious profile' }
      ]
    },
    {
      name: 'FEEDBACK',
      category: [
        { viewValue: 'Not sure how something works' },
        { viewValue: 'Report a bug' },
        { viewValue: 'Share feedback' },
        { viewValue: 'Request a feature' },
        { viewValue: 'Rate us' }
      ]
    },
    {
      name: 'ACCOUNT MANAGEMENT',
      category: [
        { viewValue: 'Update/correct profile info' },
        { viewValue: 'Delete profile' },
        { viewValue: 'Hide profile temporarily' },
        { viewValue: 'Change notification settings' },
        { viewValue: 'Other' }
      ]
    },
    {
      name: 'MEMBERSHIP BENEFITS',
      category: [
        { viewValue: 'Discuss membership plans' },
        { viewValue: 'Get personalized assistance' }
      ]
    },
    {
      name: 'WORKING WITH US',
      category: [
        { viewValue: 'Media enquiry' },
        { viewValue: 'Business enquiry' },
        { viewValue: 'Job enquiry' }
      ]
    },
    {
      name: 'OTHER',
      category: [
        { viewValue: 'Something else' }
      ]
    }
  ];
  StatusCheck;
  hideCheck = false;
  connectWithMatchCheck = false;
  deleteCheck = false;
  needHelp = false;
  handpickedMatchesCheck = false;
  checkLengthFeedback = false;
  isLiveEvents = false;
  isUnivercityData = false;
  valueForHideProfile = 'Hide profile temporarily';
  valueForDeleteProfile = 'Delete profile';
  valueForNeedHelp = 'Not sure how something works';
  valueForHandpickedMatch = 'Share feedback';
  valueForCompleteProfileViewPhone = 'Connect with a match';
  charCountDisable = false;
  numberOfCharacters = 0;
  alertRemiderBoxOne = false;
  dangerReminderBoxOne = false;
  maxNumberOfCharacters = 200;
  amplitudeData;
  faExclamationTriangle = faExclamationTriangle;
  holdSubmitButton = false;
  constructor(private mdRef: MatDialogRef<ContactUsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private sharedservices: SharedService, private localstorage: LocalStorageService, private apiservice: ApiService, private router: Router, private dataservice: DataService) {
    this.amplitudeData = this.sharedservices.amplitudeData;
    const localdata = this.localstorage.getFromLocal('user');
    this.StatusCheck = this.data.status;
    if (this.StatusCheck === 'hide') {
      this.hideCheck = true;
    }
    if (this.StatusCheck === 'connect-with-match') {
      this.connectWithMatchCheck = true;
    }
    if (this.StatusCheck === 'delete') {
      this.deleteCheck = true;
    }
    if( this.StatusCheck === 'needhelp') {
      this.needHelp = true;
    }
    if(this.StatusCheck === 'share-feedback'){
      this.handpickedMatchesCheck = true;
    }
    if(this.StatusCheck === 'live-events') {
      this.isLiveEvents = true;
    }

    if(this.StatusCheck === 'Degree-University'){
      this.isUnivercityData = true;
    }
    // console.log(this.StatusCheck);
    // saving data for the case where user reload the page so no to loose fromData.
    if (localdata) {
      this.pageTitle = this.data.from ? this.data.from : 'other';
    } else {
      this.pageTitle = 'Home-page';
    }

  }

  ngOnInit() {
    // this.dataservice.getAmplitudeData().subscribe((result : any)=> {
    //   this.amplitudeData = result;
    // });

    this.sharedservices.logAnalyticsEvent(this.amplitudeData.Contact_Us_visited);
    this.localdata = this.localstorage.getFromLocal('user');
    this.countryCodelist = [
      {
        label: 'Top countries',
        dataArray: []
      },
      {
        label: 'Next countries',
        dataArray: []
      },
      {
        label: 'Other countries',
        dataArray: []
      }
    ];

    const tempObj = {
      fistanme: '',
      lastname: '',
      email: '',
      phonenumber: '',
      countrycode: ''
    };
    //  console.log(this.localdata);
    this.dataservice.getAllCountriesCodes().subscribe(res => {
      const allcountries: any = res;
      this.countryCodelist[0].dataArray = allcountries.top;
      this.countryCodelist[1].dataArray = allcountries.next;
      this.countryCodelist[2].dataArray = allcountries.all;
      //  console.log(this.countryCodelist);

    });

    // console.log(this.countryCode);
    // const localCountryCode = this.localstorage.getFromLocal('countrycodedata');
    this.ContactusForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      countryCode: new UntypedFormControl('+91', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
      phoneNumber: new UntypedFormControl('', Validators.compose([Validators.required, Validators.min(100000), Validators.max(999999999999)])),
      subject: new UntypedFormControl('', [Validators.required]),
      textArea: new UntypedFormControl('', [Validators.maxLength(200), Validators.required])
    });

    // this.ContactusForm.valueChanges.subscribe( value => {
    //   if ( this.ContactusForm.value.textArea.length > 200) {
    //     this.checkLengthFeedback = true;
    //   } else {
    //     this.checkLengthFeedback = false;
    //   }
    // });

    this.ContactusForm.valueChanges.subscribe(result => {
      // if(this.ContactusForm.value.textArea.length > 0) {
      //   this.charCountDisable = true;
      // }else {
      //   this.charCountDisable = false; 
      // }
      
      if (this.ContactusForm.value.textArea.length === 0 || this.ContactusForm.value.textArea.length > 0) {
        this.numberOfCharacters = this.ContactusForm.value.textArea.length;
        if (this.numberOfCharacters >= 190 && this.numberOfCharacters <= 200) {
          this.alertRemiderBoxOne = true;
          this.dangerReminderBoxOne = false;
        } else if (this.numberOfCharacters > 200) {
          this.alertRemiderBoxOne = false;
          this.dangerReminderBoxOne = true;
        } else {
          this.dangerReminderBoxOne = false;
          this.alertRemiderBoxOne = false;
        }
      }
    });

    if (this.localdata) {
      tempObj.countrycode = this.localdata.countrycode;
      tempObj.email = this.localdata.email;
      tempObj.phonenumber = this.localdata.phoneNumber;
      tempObj.fistanme = this.localdata.firstname;
      tempObj.lastname = this.localdata.lastname ? this.localdata.lastname : ' ';

      this.ContactusForm.patchValue({
        firstName: tempObj.fistanme,
        lastName: tempObj.lastname,
        countryCode: '+' + tempObj.countrycode.toString(),
        phoneNumber: tempObj.phonenumber,
        email: tempObj.email
      });

      this.ContactusForm.get('firstName').disable();
      this.ContactusForm.get('lastName').disable();
      this.ContactusForm.get('countryCode').disable();
      this.ContactusForm.get('email').disable();
      this.ContactusForm.get('phoneNumber').disable();


    } else {
      // const tempvar: any = this.localstorage.getFromLocal('contactUsDetails');
      // console.log(tempvar);
      // if (!tempvar) {
      //   const contactUsDetails = {
      //     name: '',
      //     email: '',
      //     countrycode: '',
      //     mobileNumber: '',
      //     feedback: ''
      //   };
      //   this.localstorage.setToLocal('contactUsDetails', 'contactUsDetails');
      //   console.log(this.localstorage);

      // } else {
      //   this.ContactusForm.patchValue({
      //     firstName: tempvar.name,
      //     countryCode: tempvar.countrycode,
      //     email: tempvar.email,
      //     phoneNumber: tempvar.mobileNumber,
      //     textArea: tempvar.feedback
      //   });
      // }
    }
    this.apiStatus = true;
  }


  onBlurCommentMethod() {
      if (this.ContactusForm.controls.textArea.value) {
        this.ContactusForm.patchValue({
          textArea: (this.ContactusForm.controls.textArea.value).trim()
        });
      }
  }


  onSubmitContactUs() {
    if(!this.dangerReminderBoxOne){
      this.holdSubmitButton = true;
    this.isSubmitted = true;
    if (this.ContactusForm.controls.textArea.value) {
      this.ContactusForm.patchValue({
        textArea: (this.ContactusForm.controls.textArea.value).trim()
      });
    }
    if (this.deleteCheck) {
      this.sharedservices.logAnalyticsEvent(this.amplitudeData.Contact_us_submitted_delete_profile);
    }

    if (this.hideCheck) {
      this.sharedservices.logAnalyticsEvent(this.amplitudeData.Contact_us_submitted_hide_profile);
    }
    if (this.ContactusForm.status === 'VALID') {
      const apiparams = {
        firstname: this.ContactusForm.controls.firstName.value,
        lastname: this.ContactusForm.controls.lastName.value ? this.ContactusForm.controls.lastName.value : ' ',
        email: this.ContactusForm.controls.email.value,
        feedback: this.ContactusForm.controls.textArea.value,
        countrycode: this.ContactusForm.controls.countryCode.value,
        phonenumber: this.ContactusForm.controls.phoneNumber.value.toString(),
        category: this.ContactusForm.controls.subject.value,
        from: this.pageTitle
      };
      this.apiservice.contactUs(apiparams).subscribe(result => {
        if (result) {
          this.holdSubmitButton = false;
          this.sharedservices.logAnalyticsEvent(this.amplitudeData.Contact_Us_submitted);
          // console.log(apiparams);
          Swal.fire({
            icon: 'success',
            title: 'Your Feedback Has Been Recorded',
            html:
              'Our team will respond within 1 working day.<br><br><b>Please note our current operating hours:</b><br>10am–7pm IST, Monday–Friday & <br> 10am–5pm IST, Saturday & Sunday <br><br> <b> Want a quicker response?  Call us:</b> <br> +91 9345 634 365 (India) or +1 305 JODI 365 (USA)',
            showConfirmButton: false,
            showCloseButton: true
          });
          this.mdRef.close('feedbackrecorded');
        }

      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          this.holdSubmitButton = false;
          if (err.status === 401) {
          //  this.localstorage.removeToken('user');
            this.sharedservices.clearlocalstorageData();
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            this.sharedservices.gettingServerError();
          }
        });
    } else {
      this.holdSubmitButton = false;
      console.log('Form Invalid');
    }

  }
}

}
