import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from './services/api.service';
import { LocalStorageService } from './services/local-storage.service';
import { SharedService } from './services/shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataService } from './services/data.service';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart, ChildActivationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  status = false;
  analticsData;
  oneTimeCalled = true;
  showSpinner = true;
  constructor(private apiservice: ApiService, private localservice: LocalStorageService, private sharedservice: SharedService, private cd: ChangeDetectorRef, private router: Router, private dataservice: DataService, private activatedroutes: ActivatedRoute) {

    let urlParam = window.location.pathname;
    if(urlParam === '/user/settings/profile-settings' || urlParam === '/user/settings/profile'){
      this.router.navigate(['/user/my-profile']);
    }
    if(window.location.pathname === '/user/notification/interested-in-you'){
      this.router.navigate(['/user/interested-in-you']);
    }

    router.events.subscribe((val) => {
      //  console.log(val);
    //  this.showSpinner = false;
      const routeValue = val instanceof NavigationEnd;
      const routeStart = val instanceof NavigationStart;
      const childEnd = val instanceof ChildActivationEnd;
      // console.log(window);
      const notificationurl = window.location.pathname;
      if (routeStart) {
       // this.showSpinner = true;
      //  console.log('in route start');
        if (notificationurl.includes('/user/notification/')) {
          this.showSpinner = false;
        } else {
          this.showSpinner = true;
        }
      }


      if (routeValue || childEnd) {
        this.showSpinner = false;
      }
      if (routeValue) {
        if (environment.production) {
          console.log('Navigate success in prod');
          try {
            const localData = this.localservice.getFromLocal('user');
            setTimeout(() => {
              // google
              // tslint:disable-next-line: prefer-const
              const windowTemp: any = window;
              windowTemp.gtag('config', 'G-MWBQRZC0XD', { page_path: window.location.pathname });

              // fb
              windowTemp.fbq('init', '406458162896009', {
                em: localData ? localData.email ? localData.email : null : null
              });
              windowTemp.fbq('track', 'PageView');
              const url = window.location.pathname.split('/')[2];
              if (url === 'user-account-created') {
                windowTemp.fbq('track', 'Lead', {
                  value: 0.50,
                  currency: 'USD'
                });
              } else if (url === 'create-profile-done') {
                windowTemp.fbq('track', 'profile-screening', {
                  value: 1.00,
                  currency: 'USD'
                });
              }
              if (localData) {
                windowTemp.__ls('identify', {
                  name: localData.firstname ? localData.firstname : null,
                  email: localData.email ? localData.email : null
                });
              }

              // help crunch
              if (localData && windowTemp.HelpCrunch) {
                // tslint:disable-next-line: prefer-const
                const custom = {
                  email: localData.email ? localData.email : null,
                  name: localData.firstname ? localData.firstname : null,
                  PhoneNumber: localData.countrycode ? localData.countrycode : null + ' - ' + localData.phoneNumber ? localData.phoneNumber : null,
                  custom_data: {
                    Accountage: localData.accountage ? localData.accountage : null,
                    isphoneverified: localData.isphoneverified ? localData.isphoneverified : null,
                    lasturl: window.location.pathname
                  }
                };
                windowTemp.HelpCrunch('updateUser', custom);
              }

              // if (this.oneTimeCalled) {
              //   this.dataservice.getPathRoutes().subscribe(data => {
              //     if (data) {
              //       this.analticsData = data;
              //       this.oneTimeCalled = false;
              //       this.analyticsFuntion();
              //     }
              //   });
              // } else {
              //   this.analyticsFuntion();
              // }
              // tslint:disable-next-line: variable-name
              windowTemp.clicky_custom = windowTemp.clicky_custom || {};
              if (localData && localData.email) {
                windowTemp.clicky_custom.visitor = {
                  username: localData.email,
                  email: localData.email
                };
              }
            }, 1000);
          } catch {
            console.log('error in analytics');
          }
        } else {
        //  console.log('Navigate success in testing');
        }
      }


    });

    if (this.localservice.getFromLocal('user')) {
      this.apiservice.getownprofile().subscribe(res => {
        this.sharedservice.ownProfileData.next(res.userdata[0]);
        this.sharedservice.ownProfileStoreddata = res.userdata[0];

        const userdetails = res.userdata[0];
        const localdata = this.localservice.getFromLocal('user');
        localdata.profilefor = userdetails.basics.whom;
        localdata.profilepersonfirstname = userdetails.basics.prospectfirstname.charAt(0).toUpperCase() + userdetails.basics.prospectfirstname.slice(1);
        if (userdetails.currentquestionnumber) {
          localdata.currentquestionnumber = userdetails.currentquestionnumber;
        }
        // for screenings
        if (userdetails.flags) {
          localdata.showscreeningpage = userdetails.flags.showscreeningpage;
        }
        this.sharedservice.setUserIdAnalyticsEvent(userdetails.email);
        this.localservice.setToLocal('user', localdata);
        // this.status = true;
        this.cd.detectChanges();
      }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          // this.localservice.removeToken('user');
          this.sharedservice.clearlocalstorageData();
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          this.sharedservice.gettingServerError();
        }
      });
    }
    // else {
    //   this.status = true;
    // }
  }
  ngOnInit() {
    try {
       // here we check if user click through outside link its proper navigate.
       if(this.localservice.getFromLocal('logoutbyme')){
          this.localservice.removeToken('logoutbyme');
          this.localservice.removeToken('RequestUrl');
       }else {
        const localStoreURL = {
          Url : (window.location.pathname + window.location.search).substr(0),
           isStatic : true
         }
  
          if(localStoreURL.Url.indexOf("/user") > -1 ||  localStoreURL.Url.indexOf("/register") > -1){
            localStoreURL.isStatic = false;
          }
          if(window.location.pathname === '/user/settings/profile-settings' || window.location.pathname === '/user/settings/profile'){
            localStoreURL.Url = '/user/my-profile';
          }
          if(window.location.pathname === '/user/notification/interested-in-you'){
            localStoreURL.Url= '/user/interested-in-you'
          }
          this.localservice.setToLocal('RequestUrl', localStoreURL);
       }
  
      
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedroutes),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
        .subscribe(event => {
         // console.log(event);
          this.sharedservice.setTitle(event.title);
          // this.sharedservice.setMetaUrl(event.url);
          this.sharedservice.setMetaDescription(event.description);
          this.sharedservice.setMetaImage(event.image);
        });
        this.dataservice.getAmplitudeData().subscribe((result : any)=> {
      this.sharedservice.amplitudeData = result;
    });
    this.dataservice.getFbEventsData().subscribe(result =>{
      this.sharedservice.customFbEventsData = result;
    });
    this.dataservice.getAllCountriesCodes().subscribe((res : any) => {
      this.sharedservice.countryCodeData = res.list;
    })
    } catch (e) {
      console.log(e);
    }
  }

  analyticsFuntion() {
    const windowTemp: any = window;
    const eventProperties = {
      url: window.location.href
    };
    const eventname = this.analticsData ? this.analticsData.createprofile[window.location.pathname] : null;
    const eventname1 = this.analticsData ? this.analticsData.prelogin[window.location.pathname] : null;
    //  const eventname2 = this.analticsData.createprofile[window.location.pathname];
    if (eventname || eventname1) {
      if (window.location.pathname.indexOf('logout') > -1) {
        windowTemp.amplitude.getInstance().setUserId(null);
      } else {
        windowTemp.amplitude.logEvent(eventname || eventname1, eventProperties);
      }
      // windowTemp.clevertap.event.push(eventname || eventname1);
    }
  }
}
